import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Textarea = forwardRef<HTMLTextAreaElement, React.TextareaHTMLAttributes<HTMLTextAreaElement>>((props, ref) => {
  return (
    <StyledTextarea ref={ref} {...props} />
  );
});

export default Textarea;
